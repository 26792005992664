<template>
  <vue-final-modal
    class="modal-change-pass"
    :clickToClose="false"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="scroll">
      <div class="header">
        <button-modal-close @close="onCloseModal" />
        <div class="title">
          {{
            isShowEmailVerification
              ? getContent(popupsData, defaultLocalePopupsData, 'changePass.titleCheckEmail')
              : getContent(popupsData, defaultLocalePopupsData, 'changePass.title')
          }}
        </div>
      </div>

      <div class="description" v-if="!isShowEmailVerification">
        {{ getContent(popupsData, defaultLocalePopupsData, 'changePass.description') }}
      </div>

      <div class="modal-change-pass__form">
        <form-change-pass
          v-if="!isShowEmailVerification"
          @submit="onSubmitPass"
          @cancel="onCloseModal"
          :isLockedAsyncButton="isLockedAsyncButton"
        />

        <form-email-code-verify
          v-if="isShowEmailVerification"
          :email="profile?.email"
          :hint="verificationError"
          :loading="verifyLoading"
          :buttonSubmitLabel="getContent(layoutData, defaultLocaleLayoutData, 'buttons.submit')"
          :buttonCancelLabel="getContent(layoutData, defaultLocaleLayoutData, 'buttons.back')"
          @remove-hint="verificationError = undefined"
          @cancel="isShowEmailVerification = false"
          @verifyCode="changePassword"
          @resendCode="sendEmailOtp()"
        />
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  const { sendEmailOTPCodeChangePass } = useCoreAuthApi();

  import type { IChangeFormData, IFieldHint } from '@skeleton/types';
  import { ModalName } from '@skeleton/consts/modals';

  const { showAlert } = useLayoutStore();
  const { closeModal } = useModalStore();
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);
  const globalStore = useGlobalStore();
  const { getContent } = useProjectMethods();

  const {
    layoutData,
    defaultLocaleLayoutData,
    popupsData,
    defaultLocalePopupsData,
    alertsData,
    defaultLocaleAlertsData,
  } = storeToRefs(globalStore);

  const changeFormData = reactive({
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
    otp: '',
  });

  const onCloseModal = (): void => {
    closeModal(ModalName.CHANGE_PASS);
  };

  const showInvalidPassErrorAlert = (): void => {
    showAlert(alertsData.value?.profile?.invalidPassword || defaultLocaleAlertsData.value?.profile?.invalidPassword);
  };

  const sendEmailOtp = async () => {
    await sendEmailOTPCodeChangePass();
    showAlert(alertsData.value?.profile?.sentCodeVerify || defaultLocaleAlertsData.value?.profile?.sentCodeVerify);
  };

  const isLockedAsyncButton = ref<boolean>(false);
  const { changeProfilePassword } = useCoreProfileApi();
  const isShowEmailVerification = ref(false);

  const onSubmitPass = async (formData: IChangeFormData): Promise<void> => {
    if (!profile.value?.email) {
      showAlert(alertsData.value?.profile?.addEmailFirst || defaultLocaleAlertsData.value?.profile?.sentResetLink);
      onCloseModal();
      return;
    }

    try {
      isLockedAsyncButton.value = true;
      changeFormData.currentPassword = formData.currentPassword;
      changeFormData.newPassword = formData.newPassword;
      changeFormData.repeatNewPassword = formData.repeatNewPassword;

      await sendEmailOtp();
      isShowEmailVerification.value = true;
    } catch (error: any) {
      if (error.response?.status === 400 && error.response?._data.error?.message) {
        showAlert({
          title: 'Error',
          description: error.response?._data.error?.message,
          type: 'error',
        });
      }
    } finally {
      isLockedAsyncButton.value = false;
    }
  };

  const verifyLoading = ref(false);
  const verificationError = ref<IFieldHint | undefined>();

  const changePassword = async (verificationCode: string): Promise<void> => {
    if (verifyLoading.value) return;
    verifyLoading.value = true;
    changeFormData.otp = verificationCode;

    try {
      isLockedAsyncButton.value = true;
      await changeProfilePassword(changeFormData);
      showAlert(alertsData.value?.profile?.passwordChanged || defaultLocaleAlertsData.value?.profile?.passwordChanged);
      onCloseModal();
    } catch (error: any) {
      if (error.response?.status === 422) {
        showInvalidPassErrorAlert();
      }
    } finally {
      isLockedAsyncButton.value = false;
      verifyLoading.value = false;
    }
  };
</script>

<style src="~/assets/styles/components/modal/change-pass.scss" lang="scss" />
