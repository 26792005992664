<template>
  <div class="change-pass_form__wrapper">
    <div class="change-pass_form__form">
      <form-input-password
        v-model:value="changeFormData.currentPassword"
        :label="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.password.label')"
        type="password"
        name="currentPassword"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.oldPassword.placeholder') || ''"
        is-required
        :hint="setError('currentPassword')"
        @blur="v$.currentPassword?.$touch()"
        @focus="onFocus('currentPassword')"
      />

      <form-input-password
        v-model:value="changeFormData.newPassword"
        type="password"
        name="newPassword"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.newPassword.placeholder') || ''"
        is-required
        :hint="setError('newPassword')"
        @blur="v$.newPassword?.$touch()"
        @focus="onFocus('newPassword')"
        @input="inputNewPassword"
      />

      <form-input-password
        v-model:value="changeFormData.repeatNewPassword"
        type="password"
        name="repeatNewPassword"
        :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.repeatNewPassword.placeholder') || ''"
        is-required
        :hint="setError('repeatNewPassword')"
        @blur="v$.repeatNewPassword?.$touch()"
        @focus="onFocus('repeatNewPassword')"
      />
    </div>

    <div class="change-pass_form__actions">
      <button-base
        type="gray"
        size="md"
        @click="emit('cancel')"
      >
        {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.cancel') }}
      </button-base>

      <button-base
        type="primary"
        size="md"
        @click="onSubmit"
        :isDisabled="v$.$invalid || isLockedAsyncButton"
      >
        <atomic-spinner :is-shown="isLockedAsyncButton" />
        {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.save') }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import type { IChangeFormData } from '~/types';

  const globalStore = useGlobalStore();
  const { getFormRules, createValidationRules, getContent } = useProjectMethods();

  defineProps<{
    isLockedAsyncButton: boolean;
  }>();

  const emit = defineEmits(['submit', 'cancel']);

  const {
    layoutData,
    defaultLocaleLayoutData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
  } = storeToRefs(globalStore);

  const changeFormData = reactive<IChangeFormData>({
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });


  const changeRules = createValidationRules(Object.keys(changeFormData).map((field) => ({
    name: field,
    required: true,
  })));
  const changeFormRules = getFormRules(changeRules);
  const { v$, onFocus, setError } = useFormValidation(changeFormRules, changeFormData);

  const inputNewPassword = (): void => {
    if (v$.value.repeatNewPassword.$dirty) {
      const oldValue = changeFormData.repeatNewPassword;
      changeFormData.repeatNewPassword = '';
      changeFormData.repeatNewPassword = oldValue;
    }
  };

  const onSubmit = (): void => {
    if (v$.value.$invalid) return;
    v$.value.$reset();
    const validFormData = v$.value.$validate();
    if (!validFormData) return;

    emit('submit', changeFormData);
    resetForm();
  };

  const resetForm = (): void => {
    changeFormData.currentPassword = '';
    changeFormData.newPassword = '';
    changeFormData.repeatNewPassword = '';
    v$.value.$reset();
  };

</script>

<style src="~/assets/styles/components/form/change-pass.scss" lang="scss" />
